import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  GetUserDetailAction,
  addUserCardAction,
  GetUserCardsAction,
  deleteUserCardAction,
  changeUserCardAction,
  subscribePlan,
  deleteUserAccount,
  verifyDiscountCoupon,
  deleteUser,
} from '../profile/redux/action';
import { Row, Col, Modal } from 'reactstrap';
import './style.css';
import { useWindowSize } from '@react-hook/window-size';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { styles } from './style';
import AddCard from './addCard';
import UserCards from './userCards';
import toastr from 'toastr';
import Plans from './Plans';
import BillingHistory from './BillingHistory';
import { Button, CircularProgress, Button as MButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Loader from './loader';
import { colors } from '../../commonComponents/colors';
import CloseIcon from '@material-ui/icons/Close';
import Lottie from 'lottie-react';
import sadEmoji from '../../assets/animations/sadEmoji.json';
import CancelIcon from '@material-ui/icons/Cancel';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { Alert } from '@material-ui/lab';
import { handleHelpModal } from '../layout/redux/actions';
const AccountDetail = (props) => {
  const { accountDetail } = props;
  const [modalLarge2, setmodalLarge2] = React.useState(false);
  const [width] = useWindowSize();
  const [selectedPlan, setSelectedPlan] = React.useState('35/m');
  const [cb, setCB] = React.useState();
  const [confirmDialogue, setConfirmDialogue] = React.useState(false);
  const [couponModal, setCouponModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState('');
  const [userDate, setUserDate] = React.useState('');
  const [feedback, setFeedback] = React.useState('');
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [subscriptionLoader, setSubscriptionLoader] = useState({
    plan: '',
    loader: false,
  });
  const [discountPrice, setDiscountPrice] = useState('');
  const [discountId, setDiscountId] = useState('');
  const [discountCoupon, setDiscountCoupon] = useState('');
  const [hideCard, setHideCard] = useState(false);
  const [discountCouponLoader, setDiscountCouponLoader] = useState(false);
  const [userPlan, setUserPlan] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.authUser.user);
  React.useEffect(() => {
    props.GetUserCardsAction();
    var userMonth = '';
    if (accountDetail.createdMonth > 9) {
      userMonth = accountDetail.createdMonth;
    } else {
      userMonth = `0${accountDetail.createdMonth}`;
    }
    let userExpiryDate = `${userMonth}.${accountDetail.createdDate}.${accountDetail.createdYear}`;
    let finalExpiry = moment(userExpiryDate, 'MM.DD.YYYY')
      .add(14, 'days')
      .format('MM/DD/YYYY');
    setUserDate(finalExpiry);

    setDiscountCoupon(accountDetail?.promotionCoupon);
  }, [accountDetail]);
  let amount =
    (accountDetail.payDetails && accountDetail.payDetails['amount']) || 0;
  // eslint-disable-next-line

  if (accountDetail.payType && accountDetail.payType === 'Stripe') {
    amount = amount?.toFixed(2) / 100;
  } else {
    // next_billing_date = moment(next_billing_date).format("MMMM DD YYYY");
    // billing_period_start_date = moment(billing_period_start_date).format(
    //   "MMMM DD YYYY"
    // );
  }

  const getPlan = () => {
    let planName = '';
    let amount = '$0';
    if (accountDetail?.subscription === false) {
      return {
        planName: 'FREE',
        amount: '$0',
      };
    } else {
      switch (accountDetail?.subscription_plan) {
        case '35/m':
        case 'resumas_25_plan':
          planName = 'Pro-1';
          amount = '$25';
          break;

        case '75/m':
        case 'pro3':
          planName = 'Pro-3';
          amount = '$60';
          break;

        default:
          if (accountDetail?.pay_type === 'Coupon') {
            planName = 'Gift Subscription';
            amount = '$120';
          } else if (accountDetail?.pay_type === 'msgCoupon') {
            planName = 'Pre-PAID subscription';
            amount = '$60';
          } else {
            planName = '14-Days Trial';
            amount = 'trial';
          }

          break;
      }
      return {
        planName,
        amount,
      };
    }
  };
  const toggleLarge = () => {
    setmodalLarge2(!modalLarge2);
  };

  const handleSubscribe = (value) => {
    setDiscountPrice('');
    setUserPlan(value);

    toggleLarge();
  };

  const subscribeHandler = (empCoupon) => {
    if (empCoupon == '') {
      toastr.error('Please enter coupon code');
    } else if (empCoupon) {
      dispatch(
        subscribePlan(
          {
            coupon: empCoupon,
          },
          setSubscriptionLoader,
        ),
      );
      setUserPlan(0);
    } else {
      setDiscountCoupon('');
      dispatch(
        subscribePlan(
          {
            cardId: accountDetail?.payDetails?.default_source,
            planId: selectedPlan,
            discountCoupon: discountId,
          },
          setSubscriptionLoader,
        ),
      );
      setUserPlan(0);
    }

    setConfirmDialogue(false);
  };

  const cbFunc = (ok) => {
    if (ok === 'OK') {
      setLoader(false);
    }
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  function downgradeAccount() {
    if (user?.pay_type === 'ApplePay') {
      toastr.error('Subscriptions can only be cancelled from your IOS device');
      return;
    } else if (user?.pay_type === 'GooglePay') {
      toastr.error(
        'Subscriptions can only be cancelled from your Android device',
      );
      return;
    } else {
      setLoader(true);
      dispatch(deleteUserAccount(history, cbFunc, user));
    }
  }
  const handleFreePlan = () => {
    if (user?.pay_type === 'ApplePay') {
      toastr.error('Subscriptions can only be cancelled from your IOS device');
      return;
    } else if (user?.pay_type === 'GooglePay') {
      toastr.error(
        'Subscriptions can only be cancelled from your Android device',
      );
      return;
    } else {
      dispatch(deleteUserAccount(history, setCB, accountDetail));
    }
  };
  const handleCoupon = (e) => {
    e.preventDefault();
    setDiscountCoupon(e.target.value);
    setDiscountPrice('');
    setHideCard(false);
  };

  const couponCallBack = (data) => {
    setDiscountCouponLoader(false);
    if (data) {
      setDiscountPrice(data?.balance);
      setDiscountId(data?.data?.id);
      if (
        data?.data?.payType === 'msgCoupon' ||
        data?.data?.payType === 'Coupon'
      ) {
        setHideCard(true);
      } else {
        setHideCard(false);
      }
    }
  };

  const submitDiscountCoupon = (purchase = false) => {
    setDiscountCouponLoader(true);
    let data = {
      coupon: discountCoupon,
      planType: selectedPlan,
      purchase,
    };
    dispatch(verifyDiscountCoupon(data, couponCallBack));
  };

  if (props.loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        ...styles.flex,
        flexDirection: 'column',
        width: '100%',
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Row
        className="border p-2 mt-2 d-flex"
        style={{
          borderRadius: 5,
          width: width < 500 ? '100%' : width < 1400 ? '95%' : '80%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h5 className="font-weight-bold">CURRENT PLAN</h5>
          <h3 className="font-weight-bold mt-4">
            {accountDetail?.subscription_plan === '35/m'
              ? 'Pro-1'
              : accountDetail?.subscription_plan === '75/m'
              ? 'Pro-3'
              : getPlan().planName}
          </h3>
          <p className="mt-4">
            {getPlan().amount === '$0'
              ? 'FREE'
              : getPlan().amount?.toLocaleUpperCase()}{' '}
            {getPlan().amount === '$0'
              ? '(2 Reviews / Month)'
              : getPlan().amount === 'trial'
              ? '(5 Reviews)'
              : '(100 Reviews / month)'}
          </p>
          <p style={styles.expiryStyle1}>
            {getPlan().amount === '$0' ? (
              ''
            ) : getPlan().amount === 'trial' ? (
              <>Trial Expires on: {userDate}</>
            ) : (
              <>
                Renews on:{' '}
                {user?.pay_type === 'Coupon' ||
                user?.pay_type === 'msgCoupon' ||
                user?.pay_type === '' ||
                user?.pay_type === 'GooglePay' ||
                user?.pay_type === 'ApplePay'
                  ? user?.couponExpiry
                  : moment
                      .unix(
                        user?.payDetails?.invoices?.data[0]?.lines?.data[0]
                          ?.period?.end,
                      )
                      .format('DD/MM/YYYY')}
              </>
            )}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: '#4267b2',
                color: 'white',
                marginTop: 10,
              }}
              onClick={() => {
                window.scrollTo(0, 400);
              }}
            >
              Update Plan
            </Button>
            {getPlan().amount !== '$0' && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: colors.white,
                  color: colors.blue,
                  marginTop: 10,
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Cancel Plan
              </Button>
            )}
          </div>
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.red,
              color: colors.white,
              marginTop: 10,
              alignSelf: 'end',
            }}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            {deleteLoader ? (
              <BeatLoader
                css={override}
                sizeUnit={'px'}
                size={10}
                color={'white'}
                loading={true}
              />
            ) : (
              'Delete Account'
            )}
          </Button>
        </div>
      </Row>
      <Row
        className="border p-2 mt-2"
        style={{
          borderRadius: 5,
          width: width < 500 ? '100%' : width < 1400 ? '95%' : '80%',
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h5 className="font-weight-bold">Payment Method</h5>
          {/* <Button
            onClick={toggleLarge}
            size="medium"
            variant="outlined"
            // color="#4267B2"
            style={{
              // ...styles.mt_5,
              // ...styles.mb_5,
              color: '#4267B2',
              borderColor: '#4267B2',
            }}
          >
            {props.loaderStatus ? 'Loading...' : 'Payment Method'}
          </Button> */}
        </span>

        <Col lg={12}>
          <Row>
            <UserCards
              name={`${accountDetail?.firstName || ''} ${
                accountDetail?.lastName || ''
              }`}
              changeUserCardAction={props.changeUserCardAction}
              default_source={accountDetail?.payDetails?.default_source}
              deleteUserCardAction={props.deleteUserCardAction}
              usersCards={props.usersCards}
              GetUserCardsAction={props.GetUserCardsAction}
              width={width}
            />{' '}
          </Row>
        </Col>
      </Row>
      <Row
        className="border p-2 mt-2"
        style={{
          borderRadius: 5,
          width: width < 500 ? '100%' : width < 1400 ? '95%' : '80%',
        }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: width < 925 ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h5
            className="font-weight-bold pb-3"
            style={{
              width: '80vw',
            }}
          >
            All Plans
          </h5>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 5,
            }}
          >
            {/* <p
              style={{
                textAlign: 'center',
                margin: '20px',
              }}
            >
              Redeem Gift Coupon
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: width < 515 ? 'column' : 'row',
              }}
            >
              <TextField
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Enter claim code (dashes not required)"
                id="claimCode"
                variant="outlined"
                style={{
                  fontSize: '14px',
                  width: width < 600 ? '200px' : '330px',
                  marginLeft: width < 1180 ? '20px' : '0px',
                }}
                inputProps={{
                  style: { height: 9 },
                }}
              />
              <Button
                onClick={() => subscribeHandler(couponCode)}
                color="primary"
                variant="contained"
                style={{
                  marginRight: '30px',
                  marginLeft: width < 1180 ? '20px' : '10px',
                  background: '#4267b2',
                  width: width < 600 ? '200px' : '70px',
                }}
              >
                {subscriptionLoader?.plan === '' &&
                subscriptionLoader.loader ? (
                  <CircularProgress
                    style={{ color: '#fff', height: '30px', width: '30px' }}
                  />
                ) : (
                  'Apply'
                )}
              </Button>
            </div> */}
          </Row>
        </span>
        {user?.isDowngradedPeriodActive && (
          <Alert
            style={{
              width: '100%',
              margin: '0 5%',
            }}
            severity="info"
          >
            Your subscription is cancelled but you can still use premium
            features till {moment(user?.membershipEndingAt)?.format('ll')} If
            you have any query, please{' '}
            <span
              onClick={() => {
                dispatch(handleHelpModal(true));
              }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              contact us.
            </span>
          </Alert>
        )}

        <Col lg={12}>
          <Plans
            accountDetail={accountDetail}
            user={accountDetail}
            plan={getPlan}
            handleUpgrade={handleSubscribe}
            setSelectedPlan={setSelectedPlan}
            downgradePlan={props.handleChange}
            subscriptionLoader={subscriptionLoader}
            handleFreePlan={handleFreePlan}
            cb={cb}
          />
        </Col>
      </Row>
      <Row
        className="border p-2 mt-2"
        style={{
          borderRadius: 5,
          width: width < 500 ? '100%' : width < 1400 ? '95%' : '80%',
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h6 className="font-weight-bold">Billing History</h6>
        </span>
        <Col lg={12}>
          <BillingHistory user={accountDetail} />
        </Col>
      </Row>

      <Modal isOpen={modalLarge2} size="lg">
        <AddCard
          isEmployer={false}
          discountPrice={discountPrice}
          hideCard={hideCard}
          cardLength={props.usersCards.length}
          userPlan={userPlan}
          handleCoupon={handleCoupon}
          discountCoupon={discountCoupon}
          subscribeHandler={subscribeHandler}
          submitDiscountCoupon={submitDiscountCoupon}
          discountCouponLoader={discountCouponLoader}
          addUserCardAction={props.addUserCardAction}
          userDetails={{
            name: `${accountDetail?.firstName || ''} ${
              accountDetail?.lastName || ''
            }`,
            email: accountDetail?.email || '',
            phone: accountDetail?.phone || '',
          }}
          toggle={toggleLarge}
          handlePlanPopup={() => {
            if (userPlan !== 0) {
              subscribeHandler();
            }
          }}
          selectedPlan={selectedPlan}
        />
      </Modal>
      <Dialog
        open={confirmDialogue}
        onClose={() => {
          setDiscountId('');
          setConfirmDialogue(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '5px',
            paddingTop: '5px',
          }}
        >
          <div
            aria-label="close"
            onClick={() => {
              setDiscountId('');
              setConfirmDialogue(false);
            }}
            style={{
              backgroundColor: colors.red,
              color: 'white',
              borderRadius: '55%',
              padding: '1px 2px 2px 2px',
              cursor: 'pointer',
            }}
          >
            <CloseIcon
              style={{
                color: colors.red,
                cursor: 'pointer',
                fontSize: 24,
              }}
            />
          </div>
        </div>
        <DialogTitle id="alert-dialog-title">
          Subscription Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Plan:
            <strong>{selectedPlan === '35/m' ? ' Pro-1' : ' Pro-3'}</strong>
            <br />
            Amount:
            <strong>
              {discountPrice !== ''
                ? discountPrice
                : selectedPlan === '35/m'
                ? '$25'
                : '$60'}
            </strong>
            <br />
            <input
              onChange={handleCoupon}
              type={'text'}
              value={discountCoupon}
              placeholder="Enter Discount Code"
              id="claimCode"
              style={{
                marginTop: '10px',
                fontSize: '14px',
                height: '30px',
                border: '1px solid #4267B2',
                borderRadius: '5px',
                width: width < 600 ? '200px' : '330px',
                marginLeft: width < 1180 ? '20px' : '0px',
              }}
            />
            <button
              onClick={submitDiscountCoupon}
              style={{
                marginLeft: '20px',
                background: '#4267b2',
                height: '30px',
                borderRadius: '5px',
                width: width < 600 ? '200px' : '70px',
                color: '#fff',
                marginBottom: '10px',
              }}
              // disabled={discountPrice !== ''}
            >
              {discountCouponLoader ? (
                <CircularProgress
                  style={{
                    color: '#fff',
                    height: '15px',
                    width: '15px',
                    marginTop: 4,
                  }}
                />
              ) : (
                'Apply'
              )}
            </button>
            <br />
            Are you sure want to subscribe the plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={() => {
              setDiscountId('');
              setConfirmDialogue(false);
            }}
            style={{
              backgroundColor:colors.blue,
              color:'white'
            }}
            color="primary"
          >
            Cancel
          </Button> */}
          <Button
            onClick={() => subscribeHandler()}
            color="primary"
            autoFocus
            style={{
              backgroundColor: colors.blue,
              color: 'white',
            }}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
      {/* Coupon Modal */}
      <Dialog
        open={couponModal}
        onClose={() => setCouponModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Pro-6 </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this plan, please enter your SignUp Promotion Code
            Code here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Code"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCouponModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setCouponModal(false)} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          overflow: 'hidden',
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '10px',
          }}
        >
          <div
            aria-label="close"
            onClick={() => {
              setShowModal(false);
            }}
            style={{
              backgroundColor: colors.red,
              color: 'white',
              borderRadius: '55%',
              padding: '1px 2px 2px 2px',
              cursor: 'pointer',
            }}
          >
            <CloseIcon
              style={{
                color: colors.white,
                cursor: 'pointer',
                fontSize: 24,
              }}
            />
          </div>
        </div>
        <DialogContent
          style={{
            height: 390,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Lottie
            animationData={sadEmoji}
            style={{
              height: 165,
              width: 165,
            }}
          />

          <div
            style={{
              fontSize: 19,
              fontWeight: '700',
            }}
          >
            You will lose all your account data & history and your subscription
            will be cancelled.
          </div>
          <MButton
            style={{
              backgroundColor: colors.blue,
              width: 128,
              height: 37,
              borderRadius: 35,
              marginTop: 69,
              fontSize: '14px',
              color: colors.white,
            }}
            autoFocus
            onClick={() => {
              setShowModal(false);
              downgradeAccount();
            }}
          >
            Proceed
          </MButton>
        </DialogContent>
      </Dialog>
      {/* delete account modal */}
      <Dialog
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          overflow: 'hidden',
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '10px',
          }}
        >
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => {
              setShowDeleteModal(false);
            }}
          />
        </div>
        <DialogContent
          style={{
            height: 'auto',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: 400,
          }}
        >
          <div
            style={{
              fontSize: 22,
              fontWeight: '700',
            }}
          >
            Are you sure?
          </div>

          <div
            style={{
              fontSize: 15,
              fontWeight: '500',
              marginTop: 20,
              textAlign: 'center',
            }}
          >
            Your profile and data will be permanently deleted. A quick feedback
            to improve our platform is greatly appreciated.
          </div>
          <textarea
            style={{
              width: '100%',
              height: 100,
              outline: 'none',
              marginTop: 20,
              borderRadius: 5,
              padding: 10,
              border: '1px solid lightgray',
            }}
            rows={4}
            value={feedback}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
            placeholder="Please provide feedback here"
          ></textarea>
        </DialogContent>
        <DialogActions>
          <MButton
            onClick={() => {
              setShowDeleteModal(false);
            }}
            color="primary"
            variant="contained"
          >
            Cancel
          </MButton>

          <Button
            variant="contained"
            style={{
              backgroundColor: colors.red,
              color: colors.white,
            }}
            onClick={() => {
              // setShowDeleteModal(true);
              if (feedback.length > 0) {
                setDeleteLoader(true);
                dispatch(
                  deleteUser(user, feedback, history, () => {
                    setShowDeleteModal(false);
                    setDeleteLoader(false);
                  }),
                );
              } else {
                toastr.error('Please provide feedback');
              }
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ profileReducer }) => {
  return {
    accountDetail: profileReducer.accountDetail,
    detailStatus: profileReducer.detailStatus,
    usersCards: profileReducer.usersCards,
  };
};
export default connect(mapStateToProps, {
  GetUserDetailAction,
  addUserCardAction,
  GetUserCardsAction,
  deleteUserCardAction,
  changeUserCardAction,
})(AccountDetail);
