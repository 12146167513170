import React from 'react';
import { Button } from 'reactstrap';
import { CircularProgress, colors } from '@material-ui/core';
import { useWindowWidth } from '@react-hook/window-size';
import { colors as color } from '../../commonComponents/colors';
import { styles } from './style';
export default function Plans(props) {
  const {
    plan,
    handleUpgrade,
    setSelectedPlan,
    downgradePlan,
    subscriptionLoader,
    handleFreePlan,
    cb,
    accountDetail,
  } = props;
  const width = useWindowWidth();
  const subscriptionPlanData = {
    freePlan: [
      { item: '2 Job Reviews/month' },
      { item: 'Optimized Linkedin Profile', disable: true },
      { item: 'Free AI Resume Builder' },
      { item: 'Job Search' },
      { item: 'Cover Letters', disable: true },
      { item: 'Follow-up emails', disable: true },
      { item: 'Dashboard' },
      { item: '24/7 Email Support' },
      { item: 'Mobile/Web Access' },
    ],
    proPlan: [
      '100 Job Reviews/month',
      'Optimized Linkedin Profile',
      'Free AI Resume Builder',
      'Job Search',
      'Cover Letters',
      'Follow-up emails',
      'Dashboard',
      '24/7 Email Support',
      'Mobile /Web Access',
    ],
  };
  return (
    <div style={styles.planCardsContainer(width)}>
      <div
        className="border p-3 pl-4 mt-2 d-flex pb-4"
        style={styles.planCard(width)}
      >
        <div className="d-flex flex-column ">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: '17px' }}
          >
            <h4
              className="font-weight-bold "
              style={{ fontSize: '30px', marginTop: '0px' }}
            >
              FREE
            </h4>
            <h4 style={{ marginTop: '30px', fontSize: '30px' }}>$0</h4>
            <p
              style={{
                color: '#000',
                marginTop: 38,
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              Free Forever
            </p>
          </div>
          {subscriptionPlanData.freePlan.map((elem, index) => {
            return (
              <span className="mt-1" key={index}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: -10,
                    color: elem?.disable ? '#949aa1' : '#000',
                  }}
                >
                  <i
                    className={`mdi mdi-checkbox-marked-circle ${
                      elem?.disable ? '' : 'text-success'
                    }  h4 mr-2`}
                  ></i>
                  <span style={{ marginTop: 8 }}>{elem.item}</span>
                </div>
              </span>
            );
          })}
        </div>
        <div className="d-flex flex-column">
          <h1 className="font-weight-bolder mb-2 ml_15">FREE</h1>
          <center>
            {plan().amount === '$0' ? (
              <Button
                color={color.green}
                style={{
                  width: '13rem',
                  marginTop: 15,
                  marginBottom: 20,
                  backgroundColor: color.green,
                  color: 'white',
                }}
              >
                Current
              </Button>
            ) : plan().amount === 'trial' ? (
              <>
                {cb ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '18px',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    onClick={() => handleFreePlan()}
                    style={{
                      width: '13rem',
                      marginTop: 15,
                      marginBottom: 20,
                      backgroundColor: '#4267B2',
                      color: 'white',
                    }}
                    color="#4267B2"
                  >
                    Downgrade
                  </Button>
                )}
              </>
            ) : (
              <Button
                onClick={() => {
                  downgradePlan('', 4);
                }}
                style={{
                  width: '13rem',
                  marginTop: 15,
                  marginBottom: 20,
                  backgroundColor: '#4267B2',
                  color: 'white',
                }}
                color="#4267B2"
              >
                Downgrade
              </Button>
            )}
          </center>
        </div>
      </div>
      <div
        className="border p-3 pl-4 mt-2 d-flex"
        style={styles.planCard(width)}
      >
        <div className="d-flex flex-column ">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="font-weight-bold " style={{ fontSize: '45px' }}>
              Pro
            </h1>
            <h5
              className="font-weight-bold"
              style={{ marginTop: -18, fontSize: '28px' }}
            >
              1 Month
            </h5>
            <h1
              className="font-weight-bold"
              style={{ marginTop: -10, fontSize: '45px' }}
            >
              $25
            </h1>
            <p style={{ color: '#000', marginTop: -3 }}>
              <span
                style={{ marginTop: 9, fontSize: '16px', fontWeight: '600' }}
              >
                $25 Billed once a month
              </span>
            </p>
          </div>
          {subscriptionPlanData.proPlan.map((item, index) => {
            return (
              <span className="mt-1" key={index}>
                <div style={{ display: 'flex', marginBottom: -10 }}>
                  <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                  <span style={{ marginTop: 8 }}>{item}</span>
                </div>
              </span>
            );
          })}
        </div>
        <div className="d-flex flex-column">
          <h1 className="font-weight-bolder mb-2  ml_15">$25</h1>
          <center>
            {plan().amount === '$35' ? (
              <Button
                color={color.green}
                style={{
                  width: '13rem',
                  marginTop: 15,
                  marginBottom: 20,
                  backgroundColor: color.green,
                  color: 'white',
                }}
              >
                Current
              </Button>
            ) : (
              <>
                {plan().amount === '$0' || plan().amount === 'trial' ? (
                  <>
                    {subscriptionLoader?.plan === '35/m' &&
                    subscriptionLoader?.loader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '18px',
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setSelectedPlan('35/m');

                          handleUpgrade(35);
                        }}
                        style={{
                          width: '13rem',
                          marginTop: 15,
                          marginBottom: 20,
                          backgroundColor: '#4267B2',
                          color: 'white',
                        }}
                        color="#4267B2"
                      >
                        Subscribe
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {subscriptionLoader?.plan === '35/m' &&
                    subscriptionLoader?.loader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '18px',
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setSelectedPlan('35/m');

                          handleUpgrade(35);
                        }}
                        disabled={true}
                        style={{
                          width: '13rem',
                          marginTop: 15,
                          marginBottom: 20,
                          backgroundColor: color.grey,
                          color: 'white',
                        }}
                        color="#4267B2"
                      >
                        Downgrade
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </center>
        </div>
      </div>
      <div
        className="border p-3 pl-4 mt-2 d-flex position-relative"
        style={styles.planCard(width)}
      >
        <div style={{ width: '400px', height: 'auto' }}>
          <div style={styles.valuelabel}></div>
          <span style={styles.valueLabelText}>Best Value</span>
        </div>
        <div className="d-flex flex-column ">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            // style={{ marginTop: '-17px' }}
          >
            <h1 className="font-weight-bold" style={{ fontSize: '45px' }}>
              Pro
            </h1>
            <h5
              className="font-weight-bold"
              style={{ marginTop: -18, fontSize: '28px' }}
            >
              3 Months
            </h5>
            <h1
              className="font-weight-bold"
              style={{ marginTop: -10, fontSize: '45px' }}
            >
              $60
            </h1>
            <p style={{ color: '#000', marginTop: -3 }}>
              <span
                style={{ marginTop: 9, fontSize: '16px', fontWeight: '600' }}
              >
                $60 Billed every 3 months
              </span>
            </p>
          </div>
          {subscriptionPlanData.proPlan.map((item, index) => {
            return (
              <span className="mt-1" key={index}>
                <div style={{ display: 'flex', marginBottom: -10 }}>
                  <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                  <span style={{ marginTop: 8 }}>{item}</span>
                </div>
              </span>
            );
          })}
        </div>
        <div className="d-flex flex-column">
          <h1 className="font-weight-bolder mb-2  ml_15">$60</h1>
          <center>
            {plan().amount === '$75' ||
            plan().planName === 'Pre-PAID subscription' ? (
              <Button
                color={color.green}
                style={{
                  width: '13rem',
                  marginTop: 15,
                  marginBottom: 20,
                  backgroundColor: color.green,
                  color: 'white',
                }}
              >
                Current
              </Button>
            ) : (
              <>
                {subscriptionLoader?.plan === '75/m' &&
                subscriptionLoader?.loader ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '18px',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : plan().planName === 'Gift Subscription' ||
                  plan().planName === 'Pre-PAID subscription' ? (
                  <Button
                    onClick={() => {
                      setSelectedPlan('75/m');
                      handleUpgrade(75);
                    }}
                    disabled={true}
                    style={{
                      width: '13rem',
                      marginTop: 15,
                      marginBottom: 20,
                      backgroundColor: color.grey,
                      color: 'white',
                    }}
                    color="#4267B2"
                  >
                    Downgrade
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setSelectedPlan('75/m');
                      handleUpgrade(75);
                    }}
                    style={{
                      width: '13rem',
                      marginTop: 15,
                      marginBottom: 20,
                      backgroundColor: '#4267B2',
                      color: 'white',
                    }}
                    color="#4267B2"
                  >
                    Subscribe
                  </Button>
                )}
              </>
            )}
          </center>
        </div>
      </div>
      {plan().planName === 'Gift Subscription' ? (
        <div
          className="border p-3 pl-4 mt-2 d-flex"
          style={styles.planCard(width)}
        >
          <div className="d-flex flex-column ">
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-2"
              // style={{ marginTop: '-17px' }}
            >
              <h1 className="font-weight-bold" style={{ fontSize: '45px' }}>
                Pro 6
              </h1>
              <h5
                className="font-weight-bold mb-4"
                style={{ marginTop: -18, fontSize: '28px' }}
              >
                6 Months
              </h5>

              <p style={{ color: '#000', marginTop: -3 }}>
                <span
                  style={{ marginTop: 9, fontSize: '16px', fontWeight: '600' }}
                >
                  Prepay $120 for 6 months
                </span>
              </p>
            </div>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>100 Job Reviews/month</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Optimized LinkedIn Profile</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Free AI Resume Builder</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Unlimited Resume Builder</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Unlimited Job Search</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Unlimited Cover Letters</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Follow-up emails</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Dashboard</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>24/7 Email Support</span>
              </div>
            </span>
            <span className="mt-1">
              <div style={{ display: 'flex', marginBottom: -10 }}>
                <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                <span style={{ marginTop: 9 }}>Mobile / Web Access</span>
              </div>
            </span>
          </div>
          <div className="d-flex flex-column">
            <h1 className="font-weight-bolder mb-2">$120</h1>
            <center>
              <Button
                color={color.green}
                style={{
                  // width: '100%',
                  // marginTop: 30,
                  // marginBottom: 20,
                  // fontWeight: 'bold',
                  // fontSize: 16,
                  // textAlign: 'center',
                  // borderRadius: 25,
                  // backgroundColor: color.green,
                  // height: 40,
                  // paddingTop: 10,
                  // color: color.white,
                  width: '13rem',
                  marginTop: 20,
                  marginBottom: 20,
                  backgroundColor: color.green,
                  color: 'white',
                }}
              >
                Current
              </Button>
            </center>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
