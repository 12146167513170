/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from './style';
import LetterIcon from '../../assets/landingPage/letterIcon.webp';
import LinkedInIcon from '../../assets/landingPage/linkedinIcon.webp';
import ResumeIcon from '../../assets/landingPage/resumeIcon.webp';
import LetterIconActive from '../../assets/landingPage/letterIconActive.webp';
import LinkedInIconActive from '../../assets/landingPage/linkedinIconActive.webp';
import ResumeIconActive from '../../assets/landingPage/resumeIconActive.webp';
import SearchIcon from '../../assets/landingPage/search.webp';
import TabletIcon from '../../assets/landingPage/tablet.webp';
import ToolsIcon from '../../assets/landingPage/tools.webp';
import SearchIconActive from '../../assets/landingPage/searchActive.webp';
import TabletIconActive from '../../assets/landingPage/tabletActive.webp';
import ToolsIconActive from '../../assets/landingPage/toolsActive.webp';
import { useWindowWidth } from '@react-hook/window-size';
import { Col, Row } from 'reactstrap';

export default () => {
  const width = useWindowWidth();
  const IconsRow = ({ activeList = [] }) => {
    const Resume = activeList.includes('RESUME')
      ? ResumeIconActive
      : ResumeIcon;
    const Letter = activeList.includes('LETTER')
      ? LetterIconActive
      : LetterIcon;
    const LinkedIn = activeList.includes('LINKEDIN')
      ? LinkedInIconActive
      : LinkedInIcon;
    const Search = activeList.includes('SEARCH')
      ? SearchIconActive
      : SearchIcon;
    const Tablet = activeList.includes('TABLET')
      ? TabletIconActive
      : TabletIcon;
    const Tools = activeList.includes('TOOLS') ? ToolsIconActive : ToolsIcon;
    const getColor = (name) => {
      if (activeList.includes(name)) {
        return '#4267b2';
      }
      return 'grey';
    };

    return (
      <div style={{ margin: 'auto', minWidth: '200px', maxWidth: '400px' }}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span
            style={styles.iconSpan(width)}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <img alt="resume" src={Resume} width="25px" />
            <p
              style={{
                fontSize: 10,
                lineHeight: 1,
                marginTop: 5,
                color: getColor('RESUME'),
              }}
            >
              Optimize
              <br /> Resume
            </p>
          </span>
          <span
            style={styles.iconSpan(width)}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <img alt="resume" src={Letter} width="25px" />
            <p
              style={{
                fontSize: 10,
                lineHeight: 1,
                marginTop: 5,
                color: getColor('LETTER'),
              }}
            >
              Cover
              <br />
              Letter
            </p>
          </span>
          <span
            style={styles.iconSpan(width)}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <img alt="resume" src={LinkedIn} width="25px" />
            <p
              style={{
                fontSize: 10,
                lineHeight: 1,
                marginTop: 5,
                color: getColor('LINKEDIN'),
              }}
            >
              Optimize
              <br />
              LinkedIn Profile
            </p>
          </span>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <span
            style={styles.iconSpan(width)}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <img alt="resume" src={Search} width="25px" />
            <p
              style={{
                fontSize: 10,
                lineHeight: 1,
                marginTop: 5,
                color: getColor('SEARCH'),
              }}
            >
              Job Search
            </p>
          </span>
          <span
            style={styles.iconSpan(width)}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <img alt="resume" src={Tools} width="25px" />
            <p
              style={{
                fontSize: 10,
                lineHeight: 1,
                marginTop: 5,
                color: getColor('TOOLS'),
              }}
            >
              Resume Builder
            </p>
          </span>
          <span
            style={styles.iconSpan(width)}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <img alt="resume" src={Tablet} width="25px" />
            <p
              style={{
                fontSize: 10,
                lineHeight: 1,
                marginTop: 5,
                color: getColor('TABLET'),
              }}
            >
              Mobile Apps
            </p>
          </span>
        </div>
      </div>
    );
  };
  return (
    <>
      <section className="pt-5" style={styles.background}>
        <div style={styles.priceContainer}>
          <Row className="justify-content-center">
            <Col className="text-center">
              <div className="section-title mb-60">
                <h4
                  style={{ fontFamily: 'Montserrat' }}
                  className="main-title mb-4"
                >
                  Simple and Transparent Pricing
                </h4>
                <p
                  style={{ fontFamily: 'Nunito' }}
                  className="text-muted para-desc mb-0 mx-auto"
                >
                  No credit card, contract, or signature required for signup. At
                  the end of your 14-day free trial, simply enter your credit
                  card to activate your account and continue using resumas.
                  Cancel at any time.
                </p>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              paddingBottom: '50px',
              gap: '2rem',
            }}
            xs="1"
            sm="2"
            md="4"
          >
            <Col style={styles.priceCardContainer}>
              <div>
                <span className="price h3 mb-0 font-weight-bold">
                  FREE <br />
                  $0
                </span>
                <br />
                <p className="font-weight-bold">FREE Forever</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul
                    className="feature list-unstyled"
                    style={{ textAlign: 'left' }}
                  >
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>2
                      Reviews / month
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Resume Builder
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Dashboard
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      24/7 Email Support
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Mobile / Web Access
                    </li>
                  </ul>
                </div>
              </div>
              <div style={styles.marginTopAuto}>
                <IconsRow activeList={['RESUME', 'TABLET', 'TOOLS']} />
                <div style={styles.pricing}>
                  <Link
                    to="/signup"
                    className="btn btn-primary mt-4 w-75 align-items-center justify-content-center p-0"
                  >
                    <h4 style={{ marginTop: 8 }}>SignUp</h4>
                  </Link>
                </div>
              </div>
            </Col>
            <Col style={styles.priceCardContainer}>
              <div>
                <span className="price h3 mb-0 font-weight-bold">
                  Pro-1 <br />
                  $25
                </span>
                <br />
                <p className="font-weight-bold">$25 billed monthly</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul
                    className="feature list-unstyled"
                    style={{ textAlign: 'left' }}
                  >
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      100 Reviews / month
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Optimized LinkedIn Profiles
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Unlimited Resume Builder
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Unlimited Job Search
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Unlimited Cover Letters
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Dashboard
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      24/7 Email Support
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Mobile / Web Access
                    </li>
                  </ul>
                </div>
              </div>
              <div style={styles.marginTopAuto}>
                <IconsRow
                  activeList={[
                    'RESUME',
                    'LINKEDIN',
                    'TABLET',
                    'SEARCH',
                    'TOOLS',
                    'LETTER',
                  ]}
                />
                <div style={styles.pricing}>
                  <Link
                    to="/signup"
                    className="btn btn-primary mt-4 w-75 align-items-center justify-content-center p-0"
                  >
                    <h4 style={{ marginTop: 8 }}>SignUp</h4>
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              style={{
                ...styles.priceCardContainer,
                ...styles.proCard(width),
              }}
            >
              <span className="value-label">Best Value</span>
              <div>
                <div className="d-flex justify-content-center ">
                  <span
                    className="price mb-0 font-weight-bold"
                    style={{ fontSize: '45px' }}
                  >
                    Pro-3 <br /> $60
                    <br />
                    <p>Prepay $60 for 3 months</p>
                  </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul
                    className="feature list-unstyled"
                    style={{ textAlign: 'left' }}
                  >
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      100 Reviews / month
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Optimized LinkedIn Profile
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Unlimited Resume Builder
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Unlimited Job Search
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Unlimited Cover Letters
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Dashboard
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      24/7 Email Support
                    </li>
                    <li className="feature-list">
                      <i className="mdi mdi-check text-success h5 mr-1"></i>
                      Mobile / Web Access
                    </li>
                  </ul>
                </div>
              </div>
              <div style={styles.marginTopAuto}>
                <IconsRow
                  activeList={[
                    'RESUME',
                    'LINKEDIN',
                    'TABLET',
                    'SEARCH',
                    'TOOLS',
                    'LETTER',
                  ]}
                />
                <div style={styles.pricing}>
                  <Link
                    to="/signup?plan=1"
                    className="btn btn-primary mt-4 w-75 align-items-center justify-content-center p-0"
                  >
                    <h4 style={{ marginTop: 8 }}>SignUp</h4>
                  </Link>
                </div>
              </div>
            </Col>
            <Col style={styles.priceCardContainer}>
              <div>
                <p className="font-weight-bold">
                  What happens when you SignUp?
                </p>
                <p>
                  No credit card required to sign up. You get 14-day trial
                  access to use all features (reviews limited to 5). At the end
                  of the 14-day trial, simply add your credit card to UPGRADE to
                  the PRO version or keep using free version.
                </p>
                <p className="font-weight-bold mt-5">
                  Student and Veteran Discount
                </p>
                <p style={{ marginTop: 20 }}>
                  We offer a discount of 20% to eligible students and veterans
                  who SignUp for our standard{' '}
                  <span className="font-weight-bold">Pro-1</span> plan.
                </p>
              </div>
              <div
                style={{
                  ...styles.marginTopAuto,
                  textAlign: 'center',
                  fontSize: 12,
                  color: 'grey',
                  fontWeight: 'bold',
                }}
              >
                <span className="font-weight-bold ">Note :</span> All plans for
                single user optimizing only their own/single resume. <br />
                *Unlimited usage is subject to fair use policy.
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
